import { useState } from "react";
import GoogleAd from "../adFolder/GoogleAd";
import Crafting_Career_Path from '../../image/jobHuntingImage/Crafting_Career_Path.jpeg'
import Top_Careers_Introverts from '../../image/jobHuntingImage/Top_Careers_Introverts.jpeg'


const JobHunting=()=>{
    const [searchTerm, setSearchTerm] = useState('');

    const post = [
        { path: '/top-careers-for-introverts', title: 'Top Careers for Introverts in 2024', src: Top_Careers_Introverts, alt: 'Top Careers For Introverts' },
        ]
    const filteredPosts = post.filter((post)=>
        post.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    )
    return(
        <div style={{}} className="ps-md-5 pe-md-5">
            <h1>The Genius Career Blog</h1>
            <header style={{display:'flex',}}>
                <div className="pe-md-3">
                    <img  src={Crafting_Career_Path} alt="Crafting Your Career Path" loading="lazy" style={{borderRadius:'5px'}} />
                </div>
                <div>
                
            <h3 style={{fontWeight:900}}>Crafting Your Career Path: A Step-by-Step Guide</h3>
            Job applications can be a challenging process, but with the right strategies and guidance, you can successfully navigate this journey. It's important to remember that you're not alone in this, and many people find the job application process daunting. By tailoring your resume and cover letter to each specific job, researching potential employers, and practicing your interviewing skills, you can increase your chances of landing your dream job. Don't be discouraged by rejections; use them as opportunities to learn and grow. With persistence and a positive mindset, you can overcome the challenges of the job application process and achieve your career goals.
                </div>
            
            </header>
            
                <div>
                    <div>
                        <input type="text" 
                        placeholder="search your topic..."
                        value={searchTerm}
                        onClick={(e)=>setSearchTerm(e.target.value)}
                        />
                    </div>
                    {filteredPosts.map((post,index)=>(
                        <>
                        <div key={index} className="card" style={{ width: '30%', height: '430px' ,padding:'0px'}}>
                            <img style={{ width: '100%', height: '80% ',marginTop:'0px', borderRadius: '15px',padding:'0px' }} src={post.src} className="card-img-top" alt={post.alt} />
                            <div className="card-body" style={{height:'20%'}}>
                                <h3 className="card-title" style={{marginTop:'-10px',fontSize:'medium',color:'white'}}>{post.title}</h3>
                                <a href={post.path} className="btn btn-primary">Read</a>
                            </div>
                        </div>

                        {/* Insert an ad after every 4 cards */}
                        {(index + 1) % 3 === 0 && (
                            <div style={{ gridColumn: '1 / -1', textAlign: 'center', padding: '1rem' }}>
                                <div className="ad-container" style={{width:'100%',height:'300px'}}>
                                    <GoogleAd />
                                </div>
                            </div>
                        )}
                    </>
                    ))}
                </div>
        </div>
    )
}
export default JobHunting;